/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import useAnalytics from '../hooks/useAnalytics';

// components
import Tabs from '../components/common/Tabs/Tabs';

import { getLocale, getMessages } from '../locales';
import PrivacyContentCA from '../components/PrivacyContentCA';

function PrivacyPageCA() {
  const { trackEvent } = useAnalytics();
  const messages = getMessages();
  const locale = getLocale();
  const privacyContent = PrivacyContentCA[locale];

  return (
    <Layout hideNVResidentsDisclosure hideNYResidentsDisclosure hideNMLS>
      <div className="landing-container">
        <SEO metadata={{ title: config.privacyTitle }} />
        <section className="privacy-policies container">
          <Tabs
            options={messages['privacy.tabs']}
            onClick={() => trackEvent('Click Privacy Policy Tab', { Type: 'General' })}
            activeTab={1}
          />
          <div className="privacy-content">
            <section className="privacy-header">
              <h1>{messages['privacy.ca.title']}</h1>
            </section>
            <div className="content">
              { privacyContent }
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivacyPageCA;
